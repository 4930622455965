import React, { forwardRef, PropsWithChildren, useCallback, useMemo, useState } from 'react';

import classNames from 'classnames';

import NextImage, { ImageProps } from 'next/image';
import ConditionalFragment from '@/components/common/ConditionalFragment';
import { isEmptyString, isNotSet, isSet, isString } from '@/utils/formatChecker';
import Flexbox from '@/components/common/Flexbox';

interface Props extends Omit<ImageProps, 'src' | 'alt'> {
  conditional?: boolean;
  imagePlaceholder?: React.ReactElement;
  useDefaultPlaceholder?: boolean;
  // disableObjectFit?: boolean;
  // disableObjectPosition?: boolean;
  src: ImageProps['src'] | undefined;
  alt: ImageProps['alt'] | undefined;
  imageClassName?: string;
}

const DefaultPlaceholder = () => <div className={'w-full h-full bg-gray50'} />;

const FunwooImage = forwardRef<HTMLDivElement, PropsWithChildren<Props>>(
  (
    {
      conditional = true,
      className,
      alt = '',
      imagePlaceholder,
      useDefaultPlaceholder,
      width,
      height,
      // disableObjectFit,
      // disableObjectPosition,
      src = '',
      children,
      imageClassName,
      ...props
    },
    ref
  ) => {
    const [isError, setIsError] = useState<boolean>(false);

    const isNoSource = useMemo(() => {
      if (isNotSet(src)) return true;
      if (isString(src)) return isEmptyString(src);
      if ('default' in src) return isEmptyString(src.default.src);
      return isEmptyString(src.src);
    }, [src]);

    const shouldPlaceholderShow = useMemo(() => {
      return (isSet(imagePlaceholder) || useDefaultPlaceholder) && (isError || isNotSet(src) || isNoSource);
    }, [isError, imagePlaceholder, isNoSource, useDefaultPlaceholder]);

    const onLoad = useCallback(() => setIsError(false), []);
    const onError = useCallback(() => setIsError(true), []);

    return (
      <Flexbox
        ref={ref}
        conditional={conditional}
        justify='center'
        align='center'
        className={classNames(className, 'relative overflow-hidden')}
      >
        <ConditionalFragment condition={isSet(width) && isSet(height)}>
          <div
            style={{
              paddingTop: `${((Number(height) ?? 1) / (Number(width) ?? 1)) * 100}%`,
            }}
          />
        </ConditionalFragment>
        <Flexbox
          justify={'center'}
          align={'center'}
          className={classNames('w-full h-full', {
            absolute: isSet(width) && isSet(height),
          })}
        >
          {shouldPlaceholderShow && imagePlaceholder ? (
            imagePlaceholder
          ) : shouldPlaceholderShow && useDefaultPlaceholder ? (
            <DefaultPlaceholder />
          ) : (
            <NextImage
              src={src}
              alt={alt}
              onLoad={onLoad}
              onError={onError}
              className={classNames(
                'w-full',
                'h-full',
                {
                  // 'object-cover': !disableObjectFit,
                  // 'object-center': !disableObjectPosition,
                },
                imageClassName
              )}
              width={props.layout === 'fill' ? undefined : width}
              height={props.layout === 'fill' ? undefined : height}
              {...props}
            />
          )}
        </Flexbox>
        {children}
      </Flexbox>
    );
  }
);

export default FunwooImage;
