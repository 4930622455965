import React, { useCallback, useState } from 'react';

const useIsTopOrEndReached = ({
  container,
  direction = 'vertical',
}: {
  container: HTMLElement | null | undefined;
  direction?: 'vertical' | 'horizontal';
}) => {
  const [topEndReached, setTopEndReached] = useState<boolean>(true);
  const [bottomEndReached, setBottomEndReached] = useState<boolean>(true);

  const scrollPositionChecker = useCallback(
    (element?: HTMLDivElement) => {
      if (!element && !container) return;
      if (direction === 'vertical') {
        const { scrollTop = 0, scrollHeight = 0, clientHeight = 0 } = element ?? container ?? {};

        const reachTop = Math.floor(scrollTop) <= 0;
        const reachBottom = Math.ceil(scrollTop + clientHeight) >= scrollHeight;

        setTopEndReached(reachTop);
        setBottomEndReached(reachBottom);
      } else {
        const { scrollLeft = 0, scrollWidth = 0, clientWidth = 0 } = element ?? container ?? {};

        const reachTop = scrollLeft <= 0;
        const reachBottom = scrollLeft + clientWidth >= scrollWidth;
        setTopEndReached(reachTop);
        setBottomEndReached(reachBottom);
      }
    },
    [container]
  );

  const onContainerScroll = useCallback<React.UIEventHandler<HTMLDivElement>>(
    (event) => {
      if (!event.currentTarget) return;
      else scrollPositionChecker(event.currentTarget);
    },
    [scrollPositionChecker]
  );

  return {
    topEndReached,
    bottomEndReached,
    scrollPositionChecker,
    onContainerScroll,
    scroller: container,
  };
};

export default useIsTopOrEndReached;
