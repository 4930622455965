import React, { forwardRef, PropsWithChildren, useEffect, useMemo, useRef, useState } from 'react';
import classNames from 'classnames';
import useIsTopOrEndReached from '@/src/hooks/useIsTopOrEndReached';

export type ScrollableMaskContainerRef = ReturnType<typeof useIsTopOrEndReached>;

const ScrollableMaskContainer = forwardRef<
  ScrollableMaskContainerRef,
  PropsWithChildren<{
    className?: string;
    direction?: 'vertical' | 'horizontal';
  }>
>(({ children, className, direction }, ref) => {
  const [node, setNode] = useState<HTMLDivElement | null>(null);

  const dataContainerRef = useRef<HTMLDivElement | null>(null);

  const scrollableMaskContainerRef = useIsTopOrEndReached({
    container: dataContainerRef.current,
    direction,
  });

  const { onContainerScroll, topEndReached, bottomEndReached } = useMemo(
    () => scrollableMaskContainerRef,
    [scrollableMaskContainerRef]
  );

  useEffect(() => {
    if (!node) return;
    scrollableMaskContainerRef.scrollPositionChecker();
  }, [node]);

  useEffect(() => {
    if (!ref) return;
    if (typeof ref === 'function') {
      ref(scrollableMaskContainerRef);
    } else {
      ref.current = scrollableMaskContainerRef;
    }
  }, [scrollableMaskContainerRef]);

  return (
    <div
      ref={setNode}
      className={classNames('relative w-full h-full overflow-hidden', {
        flex: direction === 'horizontal',
      })}
    >
      <div
        ref={dataContainerRef}
        onScroll={onContainerScroll}
        className={classNames('scrollbar-hidden', 'w-full h-full overflow-scroll', className)}
      >
        {children}
      </div>
      <div
        className={classNames(
          'pointer-events-none absolute top-0 left-0',
          direction === 'vertical' ? 'h-24 md:h-[20%]' : 'h-full',
          direction === 'vertical' ? 'w-full' : 'w-[15%]',
          {
            block: !topEndReached,
            hidden: topEndReached,
          }
        )}
        style={{
          backgroundImage: `linear-gradient(to ${
            direction === 'vertical' ? 'bottom' : 'right'
          }, rgb(250, 250, 250) 0%, rgba(250, 250, 250, ${direction === 'vertical' ? '0.50' : '0.27'}) ${
            direction === 'vertical' ? '100%' : '75%'
          })`,
        }}
      />
      <div
        className={classNames(
          'pointer-events-none absolute bottom-0 right-0',
          direction === 'vertical' ? 'h-24 md:h-[20%]' : 'h-full',
          direction === 'vertical' ? 'w-full' : 'w-[15%]',
          {
            block: !bottomEndReached,
            hidden: bottomEndReached,
          }
        )}
        style={{
          backgroundImage: `linear-gradient(to ${
            direction === 'vertical' ? 'top' : 'left'
          }, rgb(250, 250, 250) 0%, rgba(250, 250, 250, ${direction === 'vertical' ? '0.50' : '0.27'}) ${
            direction === 'vertical' ? '100%' : '75%'
          })`,
        }}
      />
    </div>
  );
});

export default ScrollableMaskContainer;
