import { isFalse, isNotSet, isSet, isTrue } from '@/utils/formatChecker';

export function findScrollableParent(
  node: HTMLElement | null,
  axis: 'X' | 'Y' = 'Y',
  topmost: boolean = false
): HTMLElement | null {
  let element = null;
  let isLastNode = false;
  let currentNode: HTMLElement | null = node;

  while ((isTrue(topmost) && isFalse(isLastNode)) || (isFalse(topmost) && isFalse(isLastNode) && isNotSet(element))) {
    if (isSet(currentNode)) {
      let scrollable = false;

      if (axis === 'X') {
        scrollable = currentNode.scrollWidth > currentNode.clientWidth;
      } else {
        scrollable = currentNode.scrollHeight > currentNode.clientHeight;
      }

      if (scrollable) {
        element = currentNode;
      } else {
        currentNode = currentNode.parentElement;
      }
    } else {
      isLastNode = true;
    }
  }

  return element;
}
